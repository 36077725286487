import React from "react"
import { Box } from "@mui/material"
import HeroHeader from "./HeroHeader"
import HeroBottom from "./HeroBottom"
import { useBlogRootContext } from "@talentinc/gatsby-theme-ecom/contexts/BlogRootContext"

export default function HeroSection() {
  const { showAmandaCard } = useBlogRootContext()
  return (
    <Box>
      <HeroHeader />
      <HeroBottom withAmandaCard={showAmandaCard || false} />
    </Box>
  )
}
