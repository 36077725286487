import { makeStyles } from "tss-react/mui"
import { Theme } from "@mui/material"

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    // Hero section
    sectionContainer: {
      paddingLeft: "6%",
      paddingRight: "6%",
      paddingBottom: "2rem",
      paddingTop: "2rem",
      "@media (min-width: 1456px)": {
        paddingLeft: "calc((100% - 91rem)/2 + 5.5rem)",
        paddingRight: "calc((100% - 91rem)/2 + 5.5rem)",
      },
      [theme.breakpoints.down("md")]: {
        paddingBottom: "1.5rem !important",
        paddingTop: "1.5rem !important",
      },
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "1rem !important",
        paddingTop: "1rem !important",
      },
    },
    heroMainContainer: {
      backgroundColor: "#F6F7FA",
    },
    heroSection: {
      display: "flex",
      [theme.breakpoints.down("desktop")]: {},
      [theme.breakpoints.down("laptop")]: {},
      [theme.breakpoints.down("tablet")]: {
        flexDirection: "column-reverse",
        alignItems: "center",
      },
      [theme.breakpoints.down("mobile")]: {},
      justifyContent: "space-between",
    },
    heroImageWrapper: {
      flex: 1,
      [theme.breakpoints.up("xl")]: {
        marginRight: "-0.6rem !important",
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: "-0.6rem",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    heroImageWrapperMobile: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        marginTop: "12px",
      },
    },
    leftSection: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        marginTop: "-60px",
      },
      [theme.breakpoints.between(768, 900)]: {
        paddingTop: "4.5rem !important",
      },
      [theme.breakpoints.between(600, 768)]: {
        paddingTop: "0rem !important",
      },
    },
    rightSection: {
      flex: 1,
      display: "flex",
      maxWidth: "547px",
      justifyContent: "flex-end !important",
      alignItems: "center",
      marginTop: 20,
      marginBottom: "40px",
      [theme.breakpoints.down("lg")]: {
        marginRight: "-9px",
      },
    },
    callToActionTitle: {
      fontWeight: 700,
      fontSize: "42px !important",
      letterSpacing: 0.47,
      lineHeight: "48px !important",
      fontFamily: "Tex Gyre",
      maxWidth: "547px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "32px !important",
        maxWidth: "400px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "28px !important",
        lineHeight: "34px !important",
      },
      [theme.breakpoints.down("sm")]: {
        lineHeight: "34px !important",
      },
    },
    callToAction: {
      marginBottom: "20px !important",
      padding: "13px 20px",
    },
    readingTime: {
      fontSize: "20px !important",
      fontFamily: "proxima-nova",
      fontWeight: 400,
      color: "#5A6377",
      [theme.breakpoints.down("lg")]: {
        fontSize: "15px !important",
      },
      [theme.breakpoints.down("md")]: {},
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px !important",
        marginBottom: "15px",
      },
    },
    heroAuthorBox: {
      display: "flex",
      gap: "12px",
      marginBottom: "40px",
      [theme.breakpoints.down("lg")]: {
        marginBottom: "40px",
      },
      [theme.breakpoints.down("tablet")]: {
        marginBottom: "0px",
      },
    },
    heroAvatar: {
      width: "60px",
      height: "60px",
      borderRadius: "50%",
      [theme.breakpoints.down("lg")]: {
        width: "40px",
        height: "40px",
      },
      [theme.breakpoints.down("md")]: {},
      [theme.breakpoints.down("sm")]: {},
    },
    heroAuthorName: {
      height: 30,
      lineHeight: "30px !important",
      fontSize: 20,
      fontFamily: "proxima-nova",
      [theme.breakpoints.down("lg")]: {
        fontSize: "15px !important",
      },
      [theme.breakpoints.down("md")]: {},
      [theme.breakpoints.down("sm")]: {},
    },
    heroAuthorDesignation: {
      height: 30,
      lineHeight: "30px !important",
      fontSize: 20,
      fontFamily: "proxima-nova",
      color: "#5A6377",
      [theme.breakpoints.down("lg")]: {
        fontSize: 15,
      },
      [theme.breakpoints.down("md")]: {},
      [theme.breakpoints.down("sm")]: {},
    },
    heroImage: {
      width: "100%",
      objectFit: "cover",
      aspectRatio: 1,
      borderRadius: 4,
      [theme.breakpoints.down("lg")]: {},
    },
    breadCrumbSeparator: {
      color: "#5F6062",
      fontSize: 12,
    },
    cstmBreadCrumbs: {
      "& .MuiBreadcrumbs-li p, .MuiBreadcrumbs-li a": {
        color: "#5A6377",
        fontSize: "15px",
        textDecoration: "none",
        fontFamily: "proxima-nova",
        [theme.breakpoints.down("sm")]: {
          marginBottom: "1px",
          fontSize: "13px",
        },
      },
    },
    breadCrumTitle: {
      whiteSpace: "nowrap !important",
      textOverflow: "ellipsis !important",
      overflow: "hidden",
      maxWidth: "282px",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "100px",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "350px",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "15px",
      },
    },

    // body section
    blogSection: {
      "& .css-1pcq5tx-MuiGrid-root-authorContainer": {
        marginTop: "54px",
        "& .css-1e7q3py-MuiGrid-root>.MuiGrid-item": {
          paddingTop: "26px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        "& .css-1pcq5tx-MuiGrid-root-authorContainer": {
          marginTop: "54px",
          "& .css-1e7q3py-MuiGrid-root>.MuiGrid-item": {
            paddingTop: "26px",
          },
        },
      },
    },
    blogGrid: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "1rem",
      },
    },
    blogRichText: {
      fontSize: "20px !important",
      fontFamily: "proxima-nova !important",
      fontWeight: 400,
      lineHeight: "30px !important",
      [theme.breakpoints.down("lg")]: {
        fontSize: "18px !important",
        lineHeight: "26px !important",
      },
      [theme.breakpoints.down("md")]: {
        lineHeight: "26px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "17px !important",
        lineHeight: "25px !important",
      },
    },
    blogHeadings: {
      fontFamily: "Tex Gyre !important",
    },
    bodyImg: {
      width: "100%",
    },
    authorContainer: {
      paddingRight: "0rem !important",
      marginTop: "-1rem",
    },
    authorDivider: {
      marginTop: "16px",
    },
    authorNameGrid: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "16px",
      },
    },
    authorAvatarContainer: {
      [theme.breakpoints.down("sm")]: {
        "& .MuiGrid-container": {
          marginTop: "-164px !important",
        },
      },
    },
    authorLabel: {
      fontSize: "14px !important",
      fontWeight: 600,
      fontFamily: "proxima-nova !important",
      color: "#818B9E",
      marginBottom: "5px",
      lineHeight: "25px !important",
      letterSpacing: "1px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
        lineHeight: "22px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        lineHeight: "25px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px !important",
        lineHeight: "20px !important",
      },
    },
    authorName: {
      fontSize: "20px !important",
      fontFamily: "proxima-nova !important",
      fontWeight: 600,
      lineHeight: "30px",
      marginBottom: "10px !important",
      [theme.breakpoints.down("lg")]: {
        fontSize: "18px !important",
        lineHeight: "26px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        lineHeight: "26px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "17px !important",
        lineHeight: "25px !important",
      },
    },
    authorDescription: {
      fontSize: "20px !important",
      fontFamily: "proxima-nova !important",
      fontWeight: 400,
      lineHeight: "30px !important",
      [theme.breakpoints.down("lg")]: {
        fontSize: "18px !important",
        lineHeight: "26px !important",
      },
      [theme.breakpoints.down("md")]: {
        lineHeight: "26px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "17px !important",
        lineHeight: "25px !important",
      },
    },
    authorAvatar: {
      width: "96px",
      height: "96px",
      [theme.breakpoints.down("lg")]: {
        marginRight: "1rem",
      },
      [theme.breakpoints.down("sm")]: {
        width: "72px",
        height: "72px",
        marginRight: "2rem !important",
      },
    },

    articleLinks: {
      color: "#0DA1EC",
    },
    resumeStackMain: {
      position: "relative",
      height: "100%",
    },
    floatingBannerTop: {
      position: "sticky",
      top: "150px",
      "& .MuiGrid-item": {
        paddingTop: "0px !important",
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "8px !important",
      },
    },
    floatingBannerGrid: {
      display: "flex",
      justifyContent: "end",
    },
    resumeStackBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#0DA1EC",
      padding: "32px 24px",
      height: "430px",
      maxWidth: "296px",
      borderRadius: "4px",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "289px",
        width: "100%",
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "row-reverse",
        maxWidth: "768px",
        height: "264px",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        maxWidth: "100%",
        height: "430px",
      },
    },
    resumeStackImg: {
      height: "200px",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {},
    },
    resumeTitleBox: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",

      maxWidth: "291px",
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
      },
    },
    resumeTitle: {
      fontSize: "28px !important",
      fontWeight: 700,
      lineHeight: "36px !important",
      color: "white !important",
      textAlign: "center",
      fontFamily: "Tex Gyre",
      [theme.breakpoints.down("lg")]: {
        fontSize: "24px !important",
        lineHeight: "32px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "32px !important",
        lineHeight: "38px !important",
        textAlign: "left",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px !important",
        lineHeight: "32px !important",
        textAlign: "center",
      },
    },
    resumeBtn: {
      fontSize: "17px !important",
      backgroundColor: "#09143B",
      borderRadius: "6px",
      marginTop: "1rem !important",
      fontFamily: "proxima-nova",
      "&:hover": {
        backgroundColor: "#102367",
      },

      [theme.breakpoints.down("md")]: {
        height: "46px",
        width: "100%",
      },
    },
    socialIcons: {
      padding: "0rem 3rem",
      [theme.breakpoints.down("lg")]: {
        padding: "0rem 2rem",
      },
      [theme.breakpoints.down("md")]: {
        padding: "0rem 2rem",
      },
      [theme.breakpoints.up("xs")]: {},
    },
    socialIconsGrid: {
      display: "none",
    },
    socialIconsBox: {
      width: "48px !important",
      height: "48px !important",
      borderRadius: "100px",
      backgroundColor: "#F6F7FA !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    careerAdviceContainer: {
      justifyContent: "right !important",
      position: "sticky",
      top: "150px",
      [theme.breakpoints.down("md")]: {
        display: "none !important",
      },
    },
    careerAdviceBox: {
      float: "right",
      backgroundColor: "#F6F7FA",
      padding: "32px 24px",
      minHeight: "460px",
      maxWidth: "296px",
      borderRadius: "4px",
      marginRight: "8px",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        maxWidth: "289px",
        padding: "22px 24px",
      },
    },
    careerAdviceImg: {
      height: "71px",
    },
    careerAdviceTitleBox: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      gap: "1rem",
    },
    careerAdviceTitle: {
      fontSize: "28px !important",
      fontWeight: 700,
      color: "#202124 !important",
      lineHeight: "36px !important",
      fontFamily: "Tex Gyre",
    },

    careerAdviceDescription: {
      fontSize: "17px !important",
      fontFamily: "proxima-nova !important",
      fontWeight: 400,
      color: "#202124 !important",
      lineHeight: "26px !important",
    },

    careerAdviceEmailInput: {
      backgroundColor: "white",
      padding: "10px 16px",
      borderRadius: "4px",
      height: "46px",
    },
    careerAdviceBtn: {
      fontSize: "17px !important",
      fontWeight: 600,
      backgroundColor: "#0DA1EC",
      borderRadius: "6px",
      outline: "none",
      marginTop: "1rem !important",
      "&:focus": {
        outline: "none",
      },
    },
    errorMessage: {
      background: "#ff0",
      color: "#202124",
      padding: "2px 8px",
      borderRadius: "5px",
      fontWeight: 600,
      fontFamily: "proxima-nova",
      marginTop: "10px",
      fontSize: "13px",
    },
    successMessage: {
      background: "#0ed265",
      color: "#fff",
      padding: "2px 8px",
      borderRadius: "5px",
      fontWeight: 600,
      fontFamily: "proxima-nova",
      marginTop: "10px",
      fontSize: "13px",
    },

    // Related Article section //

    articleSection: {
      backgroundColor: `${theme.palette.grey[100]} !important`,
      [theme.breakpoints.down("sm")]: {
        paddingTop: "20px",
        paddingBottom: "20px",
      },
    },
    articleHeading: {
      fontSize: "30px !important",
      fontFamily: "TeX Gyre",
      fontWeight: 700,
      lineHeight: "30px !important",
      [theme.breakpoints.down("lg")]: {
        lineHeight: "42px !important",
      },
    },
    articleImg: {
      aspectRatio: "16/9",
      borderRadius: 4,
      objectFit: "cover",
      width: "100%",
      cursor: "pointer",
      transition: "0.1s all ease-in-out",
      "&:hover": {
        scale: "1.05",
      },
    },
    articleTitle: {
      fontSize: "20px",
      fontFamily: "proxima-nova",
      fontWeight: 600,
      lineHeight: "28px !important",
      textDecoration: "none",
      color: "#202124",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
        textDecorationColor: "#1a91f0",
        textDecorationThickness: "2px",
        textUnderlineOffset: "5px",
      },
    },
    footerLogoImgContainer: {
      cursor: "pointer",
      textAlign: "center",
    },
    footerIconsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 24,
      height: 24,
      borderRadius: "50%",
      background: "#B7C2D8",
      cursor: "pointer",
      "&:hover": {
        background: "#0da1ec;",
      },
    },
  }
})
