import React, { createContext, ReactNode, useContext } from "react"
import { BlogRootData } from "@talentinc/gatsby-theme-ecom/types/blog/blogRoot"

type BlogRootContext = BlogRootData & { brandName: string | null }

const initialContextValue: BlogRootContext = {
  id: null,
  title: null,
  description: null,
  showAmandaCard: true,
  featuredEditor: null,
  featuredDescription: null,
  editorPicksUrl: null,
  featuredBlogs: null,
  categories: null,
  moreTopics: null,
  metaTitle: null,
  metaDescription: null,
  canonicalUrl: null,
  indexStatus: null,
  brandName: null
}

const BlogRootContext = createContext<BlogRootContext>(initialContextValue)

export const BlogRootContextProvider = ({ children, value }: { children: ReactNode; value: BlogRootContext }) => {
  return <BlogRootContext.Provider value={value}>{children}</BlogRootContext.Provider>
}

export const useBlogRootContext = () => useContext(BlogRootContext)
