import React, { useMemo } from "react"
import { Typography, Box, Button, Breadcrumbs, Avatar } from "@mui/material"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Link, navigate } from "gatsby"
import { useArticlePageContext } from "@talentinc/gatsby-theme-ecom/contexts/ArticlePageContext"
import { calculateRichTextReadingTime } from "@talentinc/gatsby-theme-ecom/utils/blog/readingTime"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTranslation } from "react-i18next"
import { useStyles } from "./style"
import { getLinkUrl } from "@talentinc/gatsby-theme-ecom/utils/blog/url"

export default function HeroSection() {
  const { title, heroImage, author, blogCategory, updatedAt, body } = useArticlePageContext()

  const { t } = useTranslation()

  const { classes } = useStyles()
  const readingTime = useMemo(() => calculateRichTextReadingTime(body?.raw || ""), [body?.raw])
  const matchesMd = useMediaQuery("(max-width:900px)")

  const articleBreadcrumbs = () => {
    return (
      <Breadcrumbs
        className={classes.cstmBreadCrumbs}
        separator={<NavigateNextIcon className={classes.breadCrumbSeparator} />}
        aria-label="breadcrumb"
      >
        <Link to={"/"}>{t("blog.article.hero.heroBreadCrumb.homeTitle")}</Link>
        <Link to={getLinkUrl(t("blog.slugs.blogRootPage"))}>{t("blog.article.hero.heroBreadCrumb.blogTitle")}</Link>
        <Link
          to={getLinkUrl(
            t("blog.slugs.categoryPage", {
              categorySlug: blogCategory?.slug,
            })
          )}
        >
          {blogCategory?.name}
        </Link>
        <Typography className={classes.breadCrumTitle}>{title}</Typography>
      </Breadcrumbs>
    )
  }

  return (
    <Box className={classes.heroMainContainer}>
      <Box className={classes.sectionContainer}>
        {matchesMd && articleBreadcrumbs()}
        <Box className={classes.heroSection}>
          {/* hero left section  */}
          <Box className={classes.leftSection}>
            {!matchesMd && articleBreadcrumbs()}
            <Box className={classes.heroImageWrapperMobile}>
              <img className={classes.heroImage} src={heroImage?.file?.url || ""} alt={heroImage?.title || ""} />
            </Box>
            <Box>
              <Typography className={classes.callToActionTitle} variant="h1" component="h1" gutterBottom>
                {title}
              </Typography>
              <Button className={classes.callToAction} onClick={() => navigate(t("blog.slugs.resumeReviewPage"))}>
                {t("blog.article.hero.heroCTA.buttonTitle")}
              </Button>
              <Typography className={classes.readingTime}>
                {`${readingTime} ${t("blog.article.hero.readingTime.readingTimeText")}. ${t(
                  "blog.article.hero.readingTime.updatedOn"
                )} ${updatedAt}`}
              </Typography>
            </Box>

            <Box className={classes.heroAuthorBox}>
              <Avatar
                alt={author?.authorImage?.title || ""}
                src={author?.authorImage?.file?.url || ""}
                className={classes.heroAvatar}
              />
              <Box>
                <Typography className={classes.heroAuthorName}>{author?.authorName}</Typography>
                <Typography className={classes.heroAuthorDesignation}>{author?.authorTagline}</Typography>
              </Box>
            </Box>
          </Box>
          {/* hero right section  */}
          <Box className={classes.rightSection}>
            <Box className={classes.heroImageWrapper}>
              <img className={classes.heroImage} src={heroImage?.file?.url || ""} alt={heroImage?.title || ""} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
