import React from 'react'
import RoundBackgroundBlock from '@talentinc/gatsby-theme-ecom/components/RoundBackgroundBlock/RoundBackgroundBlock'
import HomePageLinkButton from '@talentinc/gatsby-theme-ecom/components/Link/HomePageLinkButton'
import { RBBVariants } from '@talentinc/gatsby-theme-ecom/types/landingPageV2'
import { Typography, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import TRResumeUpload from '@talentinc/gatsby-theme-ecom/components/Widgets/TRResumeUpload'
import { useTranslation } from 'react-i18next'
import useBrand from '../../hooks/useBrand'
import startCase from 'lodash/startCase'

interface Props {}

function HomeHero(props: Props) {
  const {} = props
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { flagshipProduct, isTopResume, isTopCvFr } = useBrand()

  const buttonLink = isTopCvFr ? '/redaction-cv' : isTopResume ? 'resume-writing' : 'cv-writing'

  return (
    <RoundBackgroundBlock noBottomPaddingMobile variant={RBBVariants.Custom}>
      <Box className={classes.container}>
        <Box className={classes.left}>
          <Typography className={classes.h1} variant={'h1'}>
            {t(`home.hero.header`, { flagshipProduct })}
          </Typography>
          <Typography variant={'h3'}>
            {t(`home.hero.subtitle`, { flagshipProduct })}
          </Typography>
          <Box className={classes.btnContainer}>
            <HomePageLinkButton
              href={buttonLink}
              position="main" // Pass position as 'main'
            >
              {t(`home.hero.btn`, { flagshipProduct: startCase(flagshipProduct) })}
            </HomePageLinkButton>
          </Box>
        </Box>
        <Box className={classes.right}>
          <Box>
            <TRResumeUpload
              options={{
                isV2: true,
                showMobileButton: true,
                mobileWidget: 'Upload Widget',
              }}
            />
          </Box>
        </Box>
        <img
          className={classes.imgBottomMobile}
          src="https://images.ctfassets.net/7thvzrs93dvf/4qjpARtpxvdg7qNmR6gxTT/fe15a0afd653bd86248c7bafe05fc9e3/savannah.png"
        />
      </Box>
    </RoundBackgroundBlock>
  )
}

export default HomeHero

const useStyles = makeStyles()((theme) => ({
  h1: {
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      fontSize: '34px',
      lineHeight: '36px',
      marginTop: 8,
    },
  },
  imgBottomMobile: {
    zIndex: 2,
    width: '100%',
    marginTop: 10,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      display: 'none',
      marginTop: 0,
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    gap: '6%',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'column',
      gap: 0,
    },
    '& h3': {
      marginTop: 20,
    },
  },
  btnContainer: {
    marginTop: 32,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: '100%',
      '& a': {
        width: '100%',
      },
    },
  },
  left: {
    display: 'flex',
    zIndex: 2,
    flexDirection: 'column',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      textAlign: 'center',
    },
  },
  right: {
    zIndex: 2,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: '100%',
    },
  },
}))
