/**
 * This method returns modified url according to the environment.
 * @param {string} url
 * @returns {string}
 */
export const getLinkUrl: (url: string) => string = (url) => {
  switch (process.env.NODE_ENV) {
    case "production":
      return url
    case "staging":
      return '/new' + url
    case "development":
      return '/new' + url
    default:
      return '/new' + url
  }
}
