import { Box, Typography, Link } from "@mui/material"
import { makeStyles } from "tss-react/mui"
import React from "react"
import { useTranslation } from "react-i18next"

function Item({ classes, item }) {
  return (
    <Link target="blank" href={item.href} className={classes.item}>
      <Box>
        <img src={item.img} />
      </Box>
      <br />
      <Typography className={classes.itemTitle} variant="h2">
        {item.description}
      </Typography>
    </Link>
  )
}

function PressHome() {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const items = t("home.press.items", {
    returnObjects: true,
    defaultValue: [],
  }) as { href: string; description: string; img: string }[]

  return (
    <Box className={classes.container}>
      <Typography variant="h2">{t("home.press.title")}</Typography>
      <Box className={classes.row}>
        {items.map((item, index) => (
          <Item key={index} item={item} classes={classes} />
        ))}
      </Box>
    </Box>
  )
}

export default PressHome

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    gap: 20,
    marginTop: 32,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: "column",
    },
  },
  item: {
    background: theme.colors.neutral[5],
    borderRadius: 20,
    padding: 32,
    flexDirection: "column",
    flex: 1,
    display: "flex",
    color: theme.colors.neutral[60],
  },
  itemTitle: {
    fontSize: "23px !important",
    lineHeight: "28px !important",
  },
}))
