import { makeStyles } from "tss-react/mui"
import { Theme } from "@mui/material"

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    // Root page hero section
    // hero top section
    rootSectionContainer: {
      paddingLeft: "6%",
      paddingRight: "6%",
      paddingBottom: "0rem",
      paddingTop: "2rem",
      "@media (min-width: 1456px)": {
        paddingLeft: "calc((100% - 91rem)/2 + 5.5rem)",
        paddingRight: "calc((100% - 91rem)/2 + 5.5rem)",
      },
      [theme.breakpoints.down("md")]: {
        paddingBottom: "1.5rem !important",
        paddingTop: "1rem !important",
      },
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "1rem !important",
      },
    },
    heroHeadMain: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: "49px",
      },
    },
    heroSearchContainer: {
      textAlign: "right",
      [theme.breakpoints.down("lg")]: {
        textAlign: "left",
      },
    },

    headerStackContainer: {
      [theme.breakpoints.down("lg")]: {
        marginBottom: "20px",
      },
    },
    heroTitle: {
      fontSize: "44px !important",
      fontWeight: 700,
      fontFamily: "Tex Gyre",
      color: "#0DA1EC",
      [theme.breakpoints.down("md")]: {
        fontSize: "35px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "30px !important",
      },
    },
    heroDescription: {
      fontSize: "24px !important",
      fontWeight: 400,
      fontFamily: "proxima-nova",
      color: "#202124",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px !important",
      },
    },

    categorySearchGrid: {
      [theme.breakpoints.down("md")]: {
        marginBottom: "20px !important",
      },
    },
    searchContainerBox: {
      position: "absolute",
      right: 0,
      height: "40px",
      [theme.breakpoints.down("lg")]: {
        right: "unset",
      },
    },
    inputContainer: {
      display: "flex",
      alignItems: "center",
      padding: "5px 10px",
      background: "white",
      border: "1px solid #D2D9E7",
      borderRadius: "3px",
      gap: "5px",
      width: "253px",
      height: "37.09px",
      input: {
        fontFamily: "proxima-nova",
        fontSize: "17px !important",
        fontWeight: 400,
        "&::placeholder": {
          fontFamily: "proxima-nova",
          fontSize: "17px !important",
          fontWeight: 400,
          color: "#B1B7C3 !importants",
        },
      },
    },
    searchIcon: { color: "#B1B7C3", fontWeight: 400 },
    searchBtnShow: {
      borderRadius: "3px",
      height: "45px",
      width: "122px",
      marginLeft: "-2px",
      cursor: "pointer",
      transition: "0.2s all ease-in-out",
      overflow: "hidden",
    },
    clearIcon: {
      cursor: "pointer",
      color: "#5071c7",
    },
    searchButton: {
      width: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      padding: 0,
      transition: "0.2s all ease-in-out",
      minHeight: "30px",
      height: "100%",
      borderRadius: "0px",
      border: "none",
    },
    leftCardDescription: {
      fontSize: "17px !important",
      fontWeight: 400,
      fontFamily: "proxima-nova",
      color: "#202124",
      lineHeight: "27.35px !important",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 7,
      overflow: "hidden",
      textOverflow: "ellipsis",
      [theme.breakpoints.down("lg")]: {
        lineHeight: "24.35px !important",
      },
      [theme.breakpoints.down("md")]: {
        lineHeight: "26.35px !important",
      },
    },
    leftCardDescriptionSpan: {
      fontSize: "17px !important",
      fontWeight: 600,
    },
    ViewAllLink: {
      padding: "0px 8px",
      fontSize: "17px !important",
      fontWeight: 600,
      fontFamily: "proxima-nova",
      color: "#0DA1EC",
      cursor: "pointer",
      "&:hover": {
        color: "#09143b",
      },
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    heroRightContainer: {
      marginTop: "-32px !important",
    },
    heroMainItemCard: {
      [theme.breakpoints.down("md")]: {
        paddingTop: "0px !important",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "8px !important",
      },
    },
    heroItemCard: {
      paddingTop: "32px !important",
    },
    rightCardContainerHero: {
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        alignItems: "center",
      },
    },
    blogLink: {
      textDecoration: "none !important",
    },
    leftCardLink: {
      color: "#0DA1EC",
    },
    rightCardContainerBody: {
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        alignItems: "start",
        paddingBottom: "30px",
      },
    },
    heroImgContainer: {
      padding: 0,
      paddingBottom: "15px",
      maxWidth: 335,
      background: "#F7F9FC",
      [theme.breakpoints.down("lg")]: {
        paddingBottom: "5px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom:"1.2rem"
      },
    },
    righCardImg: {
      width: "100%",
      aspectRatio:"4/3",
      objectFit: "cover", 
      borderRadius: "8px",
      transition: "all .2s cubic-bezier(0.36, 1.26, 0.275, 0.97)",
      boxShadow: "0 9px 20px -8px rgba(32, 33, 36, .3)",
      "&:hover": {
        scale: "1.05",
      },
      [theme.breakpoints.down("sm")]: {
        height: 75,
        width: 100,
      },
    },
    righCardLink: {
      fontSize: "17px !important",
      fontWeight: 600,
      fontFamily: "proxima-nova",
      color: "#202124",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      "&:hover": {
        textDecoration: "underline",
        textDecorationColor: "#1a91f0",
        textDecorationThickness: "2px",
        textUnderlineOffset: "5px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "12px !important",
      },
    },

    // Body CSS
    bodyCardTitle: {
      fontSize: "30px !important",
      fontWeight: 700,
      fontFamily: "Tex Gyre",
      color: "#09143B",
      marginBottom: "2rem",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "1rem",
        fontSize: "25px !important",
      },
    },
    bodyCardImg: {
      aspectRatio:"4/3",
      objectFit: "cover",
      borderRadius: "8px",
      transition: "all .2s cubic-bezier(0.36, 1.26, 0.275, 0.97)",
      boxShadow: "0 9px 20px -8px rgba(32, 33, 36, .3)",
      "&:hover": {
        scale: "1.05",
      },
      [theme.breakpoints.down("sm")]: {
        width: 100,
        height: 75,
      },
    },
    viewAllGrid: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    viewAllButton: {
      color: "#09143B !important",
      background: "transparent !important",
      border: "1px solid #09143B",
      borderRadius: "5px",
      outline: "none",
      height: 27,
      width: 73,
      padding: "6px 16px",
      fontSize: "12px !important",
      "&:hover": {
        border: "1px solid #09143B",
        boxShadow: "none",
        background: "#c7cad4 !important",
      },
      "&:focus": {
        outline: "none",
        border: "1px solid #09143B",
      },
      "&:active": {
        outline: "none",
      },
    },

    // Extra Topic Block
    listItem: {
      fontSize: "17px !important",
      fontWeight: 600,
      fontFamily: "proxima-nova",
      color: "#0DA1EC",
      cursor: "pointer",
    },
    extraTopicBox: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  }
})
