import React from "react"
import Section from "@talentinc/gatsby-theme-ecom/components/Section/Section"
import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import { useStyles } from "../style"
import { Link } from "gatsby"
import { getLinkUrl } from "@talentinc/gatsby-theme-ecom/utils/blog/url"
import { useTranslation } from "react-i18next"
import { BlogRootData } from "types/blog/blogRoot"

const BodyFeatureSection = ({
  categoryDetails,
  title,
  viewAllUrl,
  bgColor,
}: {
  categoryDetails: Exclude<Exclude<BlogRootData["categories"], null>[number]["topArticles"], null>
  title: string
  viewAllUrl: string
  bgColor: string
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <Box sx={{ background: bgColor, borderTop: "1px solid #e4e8f0" }}>
      <Section>
        <Grid container spacing={{ xs: 1, lg: 2 }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={10} xs={12}>
                <Typography className={classes.bodyCardTitle}>{title}</Typography>
              </Grid>
              <Grid item sm={2} textAlign="right" className={classes.viewAllGrid}>
                <Link
                  className={classes.blogLink}
                  to={getLinkUrl(t("blog.slugs.categoryPage", { categorySlug: viewAllUrl }))}
                >
                  <Button className={classes.viewAllButton}>{t("blog.blogHome.category.viewALlButtonText")}</Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={{ lg: 8, md: 6, sm: 4 }}>
              {categoryDetails.slice(0, 4).map((category) => (
                <Grid item md={3} sm={6} xs={12} key={category.id}>
                  <Link
                    className={classes.blogLink}
                    to={getLinkUrl(t("blog.slugs.articlePage", { articleSlug: category.slug }))}
                  >
                    <Stack spacing={2} className={classes.rightCardContainerBody}>
                      <Box component="img" src={category?.heroImage?.file?.url || ""} className={classes.bodyCardImg} />
                      <Typography className={classes.righCardLink}>{category.title}</Typography>
                    </Stack>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Section>
    </Box>
  )
}

export default BodyFeatureSection
