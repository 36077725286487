import React, { createContext, ReactNode, useContext } from "react"
import { BlogCategorySearchData } from "@talentinc/gatsby-theme-ecom/types/blog/category"

const initialContextValue = {
  allCategory: null,
  localSearchBlogs: {
    index: "",
    store: {},
  },
}

const CategorySearchContext = createContext<BlogCategorySearchData>(initialContextValue)

export const CategorySearchContextProvider = ({
  children,
  value,
}: {
  children: ReactNode
  value: BlogCategorySearchData
}) => {
  return <CategorySearchContext.Provider value={value}>{children}</CategorySearchContext.Provider>
}

export const useCategorySearchContext = () => useContext(CategorySearchContext)
