import React from "react"
import TopLayout from "@talentinc/gatsby-theme-ecom/layouts/TopLayout"
import { PageContextType, PageDataType } from "@talentinc/gatsby-theme-ecom/types/page"
import FooterCTA from "@talentinc/gatsby-theme-ecom/components/FooterCTA/FooterCTA"
import Footer from "@talentinc/gatsby-theme-ecom/components/Footer/Footer"
import HeroSection from "@talentinc/gatsby-theme-ecom/components/Blog/Root/Hero"
import BodySection from "@talentinc/gatsby-theme-ecom/components/Blog/Root/Body"
import ExtraTopicBlock from "@talentinc/gatsby-theme-ecom/components/Blog/Root/ExtraTopicBlock"

export default function BlogHomePage({ pageContext, pageData }: { pageContext: PageContextType; pageData: PageDataType }) {
  return (
    <TopLayout pageContext={pageContext} pageData={pageData}>
      <HeroSection />
      <BodySection />
      <ExtraTopicBlock />
      <FooterCTA />
      <Footer />
    </TopLayout>
  )
}
