import { graphql, PageProps } from "gatsby"
import React from "react"
import ArticlePage from "@talentinc/gatsby-theme-ecom/pages/blog/ArticlePage"
import pageData from "../../../data/career-advice/article-page"
import { ArticlePageContextProvider } from "@talentinc/gatsby-theme-ecom/contexts/ArticlePageContext"
import { ArticleData } from "@talentinc/gatsby-theme-ecom/types/blog/article"
import { PageContextType } from "@talentinc/gatsby-theme-ecom/types/page"
import { SiteMetadata } from "@talentinc/gatsby-theme-ecom/hooks/useSiteMetadata"
import NotFoundPage from "../../404"

export default function Page({
  pageContext,
  data,
}: PageProps<
  {
    articleDetails: ArticleData
    site: {
      siteMetadata: Pick<SiteMetadata, "brandName">
    }
  },
  PageContextType
>) {
  const { articleDetails, site: { siteMetadata: { brandName } } } = data

  const updatedPageData = {
    ...pageData,
    title: articleDetails.title,
    slug: articleDetails.slug,
    seo: {
      ...pageData.seo,
      pageTitle: articleDetails?.metaTitle,
      description: articleDetails?.metaDescription,
      noIndex: !articleDetails?.indexStatus,
      canonicalUrl: articleDetails?.canonicalUrl,
    },
  }

  if (!articleDetails.domain?.includes(brandName)) {
    return <NotFoundPage />
  }

  return (
    <ArticlePageContextProvider value={articleDetails}>
      <ArticlePage pageContext={pageContext} pageData={updatedPageData} />
    </ArticlePageContextProvider>
  )
}

export const query = graphql`
  query ($id: String, $language: String) {
    site: site {
      siteMetadata {
        brandName
      }
    }
    articleDetails: contentfulBlogArticle(id: { eq: $id }) {
      id
      title
      body {
        raw
        references {
          contentful_id
          title
          file {
            contentType
            url
          }
        }
      }
      createdAt(formatString: "MMMM DD, YYYY")
      updatedAt(formatString: "MMMM DD, YYYY")
      author {
        id
        authorName
        authorQualifications
        authorTagline
        authorDescription {
          raw
        }
        authorImage {
          file {
            url
          }
          title
        }
      }
      heroImage {
        file {
          url
        }
        title
      }
      blogCategory {
        id
        slug
        name
      }
      metaTitle
      metaDescription
      indexStatus
      slug
      floatingSideBanners {
        sideBannerLinkUrl
        sideBannerImage {
          url
          title
        }
      }
      relatedArticles {
        id
        title
        slug
        heroImage {
          file {
            url
          }
        }
      }
      indexStatus
      canonicalUrl
      domain
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
