import React, { useState } from "react"
import { Box, Button, Grid, InputBase, Stack, Typography } from "@mui/material"

import SearchIcon from "@mui/icons-material/Search"
import { useStyles } from "../style"
import { useBlogRootContext } from "@talentinc/gatsby-theme-ecom/contexts/BlogRootContext"
import { navigate } from "gatsby"
import ClearIcon from "@mui/icons-material/Clear"
import { getLinkUrl } from "@talentinc/gatsby-theme-ecom/utils/blog/url"
import { useTranslation } from "react-i18next"

const HeroHeader = () => {
  const { classes } = useStyles()
  const [searchText, setSearchText] = React.useState<string>("")
  const [searchBtn, setSearchBtn] = useState<Boolean>(false)

  const { t } = useTranslation()

  const {
    title,
    description,
  } = useBlogRootContext()

  const handleSearchSubmit = (value: string) => {
    if (!value) return
    const urlParams = new URLSearchParams({ kw: value })
    const navigationUrl = getLinkUrl(
      t("blog.slugs.categorySearchPage", {
        queryParams: urlParams.toString(),
      })
    )
    navigate(navigationUrl)
  }

  return (
    <Box className={classes.rootSectionContainer}>
      <Grid container>
        <Grid item xs={12} className={classes.heroHeadMain}>
          <Grid container>
            <Grid item lg={8} xs={12}>
              <Stack spacing={{ sm: 2, xs: 1 }} className={classes.headerStackContainer}>
                <Typography variant="h1" className={classes.heroTitle}>
                  {title}
                </Typography>
                <Typography className={classes.heroDescription}>{description?.description}</Typography>
              </Stack>
            </Grid>
            <Grid item lg={4} xs={12}>
              <Grid container>
                <Grid className={classes.categorySearchGrid} sm={12} position="relative" item>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      handleSearchSubmit(searchText)
                    }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"end"}
                      alignItems={"center"}
                      className={classes.searchContainerBox}
                    >
                      <Box className={classes.inputContainer}>
                        <SearchIcon className={classes.searchIcon} />
                        <InputBase
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          placeholder={t("blog.blogHome.hero.heroSearchPlaceholder")}
                          onFocus={() => setSearchBtn(true)}
                          onBlur={() => setSearchBtn(false)}
                        />
                        <Box
                          onMouseDown={(e) => {
                            e.stopPropagation()
                            setSearchText("")
                          }}
                        >
                          <ClearIcon
                            className={classes.clearIcon}
                            sx={{ display: `${searchText ? "block" : "none"}` }}
                          />
                        </Box>
                      </Box>
                      <Button type="submit" className={searchBtn ? classes.searchBtnShow : classes.searchButton}>
                        {t("blog.blogHome.hero.searchButtonText")}
                      </Button>
                    </Stack>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default HeroHeader
