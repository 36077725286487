import React from "react"
import { Box, Typography } from "@mui/material"
import { useStyles } from "./style"
import { navigate } from "gatsby"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { useBETelemetry } from "@talentinc/gatsby-theme-ecom/hooks/useTelemetry"
import { useTranslation } from "react-i18next"
import { AllCategory } from "@talentinc/gatsby-theme-ecom/types/blog/category"
import { getLinkUrl } from "@talentinc/gatsby-theme-ecom/utils/blog/url"

export default function CategoryDropdown({ allCategory }: { allCategory: AllCategory }) {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const telemetry = useBETelemetry()

  const handleBlogCategoryChange = (slug: string, label: string) => {
    const { href } = window.location
    telemetry.track({
      event: "click_cta",
      properties: {
        label,
        url: href,
        cta: label,
      },
    })

    if (slug === "" || slug === undefined) return

    const url = getLinkUrl(t("blog.slugs.categoryPage", { categorySlug: slug }))
    navigate(url)
  }

  return (
    <Box className={classes.DropdownMainBox}>
      <Box className={classes.DropdownBox}>
        <Typography> {t("blog.category.hero.dropdownTitle")}</Typography>
        <KeyboardArrowDownIcon />
      </Box>
      <Box className={classes.categoryList}>
        <ul>
          {allCategory.map((category, i) => category.slug && category.name ? (
            <li key={i} onClick={() => handleBlogCategoryChange(category.slug || "", category.name || "")}>
              {category.name.toUpperCase()}
            </li>) : <></>
          )}
        </ul>
      </Box>
    </Box>
  )
}
