import { graphql, PageProps } from "gatsby"
import React from "react"
import CategorySearchPage from "@talentinc/gatsby-theme-ecom/pages/blog/CategorySearchPage"
import pageData from "../../../data/career-advice/search-page"
import { CategorySearchContextProvider } from "@talentinc/gatsby-theme-ecom/contexts/CategorySearchContext"
import { SearchPageData } from "@talentinc/gatsby-theme-ecom/types/blog/category"
import { PageContextType } from "@talentinc/gatsby-theme-ecom/types/page"

export default function Page({ pageContext, data }: PageProps<SearchPageData, PageContextType>) {
  return (
    <CategorySearchContextProvider
      value={{
        localSearchBlogs: data.localSearchBlogs,
        allCategory: data.allCategory.nodes,
      }}
    >
      <CategorySearchPage pageContext={pageContext} pageData={pageData} />
    </CategorySearchContextProvider>
  )
}

export const query = graphql`
  query ($language: String) {
    allCategory: allContentfulBlogCategory(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        id
        name
        slug
        backgroundColor
      }
    }
    localSearchBlogs: localSearchBlogs {
      index
      store
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
