import { SEO } from "@talentinc/gatsby-theme-ecom/types/page"
import { brand } from "../common/brand"
import { footer } from "../common/footer"
import { headerV2 } from "../common/headerV2"

export default {
  title: "",
  slug: "",
  seo: {
    pageTitle: null,
    description: null,
    openGraphTitle: null,
    openGraphDescription: null,
    openGraphImage: null,
    noIndex: false,
    doNotDeployToProduction: false,
    pageType: null,
    canonicalUrl: null,
  } as Required<SEO>,
  ...headerV2,
  ...brand,
  ...footer,
}
