import React from "react"
import TopLayout from "@talentinc/gatsby-theme-ecom/layouts/TopLayout"
import { PageContextType, PageDataType } from "@talentinc/gatsby-theme-ecom/types/page"
import HeroSection from "@talentinc/gatsby-theme-ecom/components/Blog/Category/HeroSection"
import BodySection from "@talentinc/gatsby-theme-ecom/components/Blog/Category/BodySection"
import Footer from "@talentinc/gatsby-theme-ecom/components/Footer/Footer"
import { useCategorySearchContext } from "@talentinc/gatsby-theme-ecom/contexts/CategorySearchContext"
import useSearchParam from "react-use/lib/useSearchParam"
import { useFlexSearch } from "react-use-flexsearch"
import { navigate } from "gatsby"
import { SearchResults } from "@talentinc/gatsby-theme-ecom/types/blog/category"
import { useTranslation } from "react-i18next"
import { getLinkUrl } from "@talentinc/gatsby-theme-ecom/utils/blog/url"

export default function CategorySearchPage({
  pageContext,
  pageData,
}: {
  pageContext: PageContextType
  pageData: PageDataType
}) {
  const { allCategory, localSearchBlogs } = useCategorySearchContext()
  const searchQuery = useSearchParam("kw") || ""
  const { t } = useTranslation()
  const results: SearchResults = useFlexSearch(searchQuery, localSearchBlogs.index, localSearchBlogs.store, {
    tokenize: "forward",
  })

  const handleSearchSubmit = (value: string) => {
    if (!value) return
    const urlParams = new URLSearchParams({ kw: value })
    const url = getLinkUrl(t("blog.slugs.categorySearchPage", { queryParams: urlParams.toString() }))
    navigate(url)
  }

  return (
    <TopLayout pageContext={pageContext} pageData={pageData}>
      <HeroSection searchVal={searchQuery} allCategory={allCategory || []} onSearchSubmit={handleSearchSubmit} />
      <BodySection
        searchVal={searchQuery}
        articlesData={results}
        pagination={null}
        onSearchSubmit={handleSearchSubmit}
        noResultText={`${t("blog.category.body.searchResultTitle")} "${searchQuery}"`}
      />
      <Footer />
    </TopLayout>
  )
}
