import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import Section from "@talentinc/gatsby-theme-ecom/components/Section/Section"
import { useStyles } from "../style"
import { useBlogRootContext } from "@talentinc/gatsby-theme-ecom/contexts/BlogRootContext"
import { Link } from "gatsby"
import { getLinkUrl } from "@talentinc/gatsby-theme-ecom/utils/blog/url"
import { useTranslation } from "react-i18next"

const ExtraTopicBlock = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { moreTopics, brandName } = useBlogRootContext()

  const domainFilteredMoreTopics = moreTopics
    ? moreTopics.filter((topic) => topic.domain && brandName && topic.domain.includes(brandName))
    : []

  return (
    <Box className={classes.extraTopicBox}>
      <Section>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className={classes.bodyCardTitle}>{t("blog.blogHome.extraTopics.extraTopicTitle")}</Typography>
          </Grid>
          {domainFilteredMoreTopics.map((moreTopic) => (
            <Grid md={3} sm={6} item key={moreTopic.id}>
              <Link
                className={classes.blogLink}
                to={getLinkUrl(t("blog.slugs.categoryPage", { categorySlug: moreTopic.slug }))}
              >
                <Typography className={classes.listItem}>{moreTopic.name}</Typography>
              </Link>
            </Grid>
          ))}{" "}
        </Grid>
      </Section>
    </Box>
  )
}

export default ExtraTopicBlock
