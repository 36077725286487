import React from 'react'
import { Typography } from '@mui/material'
import LinkButton from '@talentinc/gatsby-theme-ecom/components/Link/LinkButton'
import FooterCTABgImage from '@talentinc/gatsby-theme-ecom/images/footer-cta-bg.webp'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import useBrand from '../../hooks/useBrand'
import startCase from 'lodash/startCase'

const FooterCTA = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { flagshipProduct, isTopCvFr } = useBrand()

  const testimonialsButtonUrl = isTopCvFr ? '/a-propos' : '/success-stories'

  return (
    <>
      <div className={classes.footerWrapper}>
        <div className={classes.footerContent}>
          <Typography
            align="center"
            variant="h1"
            sx={(theme) => ({ marginBottom: theme.spacing(4) })}
          >
            {t('footerCTA.title')}
          </Typography>

          <div className={classes.buttons}>
            <LinkButton
              variant="contained"
              href="#"
              onClick={(e) => {
                e.preventDefault()
                window.scrollTo({
                  top: 0, // Scroll to the top of the page
                  behavior: 'smooth', // Enables smooth scrolling
                })
              }}
            >
              {t('footerCTA.resumeReviewButton', {
                flagshipProduct: startCase(flagshipProduct),
              })}
            </LinkButton>

            <LinkButton href={testimonialsButtonUrl} variant="outlined">
              {t('footerCTA.testimonialsButton')}
            </LinkButton>
          </div>
        </div>
      </div>

      <div className={classes.footerBackground} />
    </>
  )
}

export default FooterCTA

export const useStyles = makeStyles()((theme) => ({
  footerWrapper: {
    backgroundImage: `url(${FooterCTABgImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    marginTop: theme.spacing(12),
    padding: theme.spacing(12),
    borderRadius: theme.spacing(4),
    zIndex: 1,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(8, 3),
      marginTop: theme.spacing(5),
    },
  },
  footerContent: {
    width: 520,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  footerBackground: {
    marginTop: -50,
    height: 50,
    backgroundColor: theme.colors.backgrounds.black,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(1.5),
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      rowGap: theme.spacing(1),
    },
  },
}))
