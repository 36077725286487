import { Document } from '@contentful/rich-text-types'

/**
 * Calculate rich text reading time
 * @param { string } richText
 * @param { number } [wordsPerMinute=200]
 * @returns { number } readingTimeMinutes
 */
export function calculateRichTextReadingTime(
  richText: string,
  wordsPerMinute: number = 200
): number {
  try {
    const document: Document = JSON.parse(richText)

    const flattenedArray = document.content.flatMap((value) => value.content)

    const plainText = flattenedArray
      .map((content: object) => {
        if ('value' in content) {
          return content.value
        }
        return ''
      })
      .join(' ')

    const wordCount: number = plainText.split(/\s+/).filter((word) => word).length

    const readingTimeMinutes = Math.ceil(wordCount / wordsPerMinute)

    return readingTimeMinutes
  } catch (error) {
    return 0
  }
}
