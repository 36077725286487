import { SEO } from "@talentinc/gatsby-theme-ecom/types/page"
import { brand } from "../common/brand"
import { footer } from "../common/footer"
import { headerV2 } from "../common/headerV2"

export default {
  title: "Blog Search Page",
  slug: "search",
  seo: {
    pageTitle: "Resume Critique Services, Resume Review, Resume Writer",
    description:
      "TopResume is the world’s largest professional resume writing service providing resume, cover letter and LinkedIn profile writing services",
    openGraphTitle: null,
    openGraphDescription: null,
    openGraphImage: null,
    noIndex: false,
    doNotDeployToProduction: false,
    pageType: null,
    canonicalUrl: null,
  } as Required<SEO>,
  ...headerV2,
  ...brand,
  ...footer,
}
