import React, { createContext, ReactNode, useContext } from "react"
import { ArticleData } from "@talentinc/gatsby-theme-ecom/types/blog/article"

const initialArticleData: ArticleData = {
  id: null,
  title: null,
  body: null,
  createdAt: null,
  updatedAt: null,
  author: null,
  heroImage: null,
  blogCategory: null,
  floatingSideBanners: null,
  metaTitle: null,
  metaDescription: null,
  indexStatus: null,
  slug: null,
  relatedArticles: null,
  canonicalUrl: null
}

const ArticlePageContext = createContext<ArticleData>(initialArticleData)

export const ArticlePageContextProvider = ({ children, value }: { children: ReactNode; value: ArticleData }) => {
  return <ArticlePageContext.Provider value={value}>{children}</ArticlePageContext.Provider>
}

export const useArticlePageContext = () => useContext(ArticlePageContext)
