import React from "react"
import { graphql, PageProps } from "gatsby"
import BlogHomePage from "@talentinc/gatsby-theme-ecom/pages/blog/BlogHomePage"
import pageData from "../../../data/career-advice/blog-home"
import { BlogRootData } from "@talentinc/gatsby-theme-ecom/types/blog/blogRoot"
import { BlogRootContextProvider } from "@talentinc/gatsby-theme-ecom/contexts/BlogRootContext"
import { PageContextType } from "@talentinc/gatsby-theme-ecom/types/page"
import { SiteMetadata } from "@talentinc/gatsby-theme-ecom/hooks/useSiteMetadata"

export default function Page({
  pageContext,
  data,
}: PageProps<{ blogRootPageData: { nodes: [BlogRootData] }, site: {siteMetadata:Pick<SiteMetadata, "brandName">} }, PageContextType>) {
  const { blogRootPageData } = data

  const updatedPageData = {
    ...pageData,
    seo: {
      ...pageData.seo,
      pageTitle: blogRootPageData.nodes[0]?.metaTitle,
      description: blogRootPageData.nodes[0]?.metaDescription,
      noIndex: !blogRootPageData.nodes[0]?.indexStatus,
      canonicalUrl: blogRootPageData.nodes[0]?.canonicalUrl,
    },
  }

  return (
    <BlogRootContextProvider value={{...data.blogRootPageData.nodes[0], brandName: data.site.siteMetadata.brandName}}>
      <BlogHomePage pageData={updatedPageData} pageContext={pageContext} />
    </BlogRootContextProvider>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site: site {
      siteMetadata {
        brandName
      }
    }
    blogRootPageData: allContentfulBlogRootPage(
      limit: 1
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        id
        title
        description {
          description
        }
        showAmandaCard
        featuredEditor {
          file {
            url
          }
          title
        }
        featuredDescription {
          raw
        }
        editorPicksUrl {
          slug
          name
        }
        featuredBlogs {
          id
          title
          slug
          heroImage {
            file {
              url
            }
            title
          }
          domain
        }
        categories {
          id
          name
          slug
          topArticles {
            id
            title
            slug
            heroImage {
              file {
                url
              }
              title
            }
            domain
          }
          domain
        }
        moreTopics {
          id
          name
          slug
          domain
        }
        metaTitle
        metaDescription
        indexStatus
        canonicalUrl
      }
    }
  }
`
