import { makeStyles } from "tss-react/mui"
import { Theme } from "@mui/material"

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    // Category Page
    categorySectionContainer: {
      paddingLeft: "6%",
      paddingRight: "6%",
      paddingBottom: "2rem",
      paddingTop: "0.6rem",
      "@media (min-width: 1456px)": {
        paddingLeft: "calc((100% - 91rem)/2 + 5.5rem)",
        paddingRight: "calc((100% - 91rem)/2 + 5.5rem)",
      },
      [theme.breakpoints.down("md")]: {
        paddingBottom: "1.5rem !important",
      },
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "1rem !important",
      },
    },
    DropdownMainBox: {
      position: "relative",
    },
    DropdownBox: {
      fontFamily: "proxima-nova !important",
      fontWeight: 400,
      fontSize: "17px !important",
      cursor: "pointer !important",
      border: "1px solid #09143B !important",
      color: "#09143B",
      display: "flex",
      alignItems: "center",
      padding: "5px 10px",
      borderRadius: "3px",
      gap: "15px",
      width: "230.53px",
      height: "42.09px",
      "&:hover": {
        background: "#09143b33",
      },
      "&:hover ~ .MuiBox-root": {
        display: "block !important",
        visibility: "visible",
        opacity: 1,
      },
    },
    categoryList: {
      position: "absolute",
      top: "26px",
      width: "250px",
      visibility: "hidden",
      transition: "0.5s ease-in-out",
      opacity: "0",
      zIndex: 5,
      ul: {
        padding: "0",
        listStyleType: "none",
        width: "230px",
        background: "#edf0f5",
        border: "0.5px solid #DBE0EB",
        zndex: 10,
        maxHeight: "320px",
        overflowY: "scroll",
        li: {
          fontFamily: "proxima-nova !important",
          fontWeight: 400,
          fontSize: "12px !important",
          letterSpacing: "0.1em",
          cursor: "pointer !important",
          padding: "12px 10px",
          borderBottom: "1px solid #DBE0EB",
          "&:hover": {
            background: " #0DA1EC",
            color: "#fff",
          },
        },
      },
      "&:hover": {
        display: "block !important",
        visibility: "visible",
        opacity: 1,
      },
    },
    dropDownGrid: {
      marginBottom: "15px",
    },
    categorySearchGrid: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    categorHeader: {
      background: "#E4E8F0",
    },
    categorBody: {},
    CategoryHeaderTitle: {
      fontSize: "44px !important",
      lineHeight: "52.8px !important",
      fontWeight: 700,
      color: "#0DA1EC",
      fontFamily: "Tex Gyre",
      padding: "6px 0",
      marginTop: "3rem",
      [theme.breakpoints.down("lg")]: {
        padding: "20px 0",
        marginTop: "1rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "30px !important",
        lineHeight: "36px !important",
        marginTop: "0px",
        padding: "6px 0",
      },
    },

    inputContainer: {
      display: "flex",
      alignItems: "center",
      padding: "5px 10px",
      background: "white",
      border: "1px solid #D2D9E7",
      borderRadius: "3px",
      gap: "5px",
      width: "253px",
      height: "37.09px",
      input: {
        fontFamily: "proxima-nova",
        fontSize: "17px !important",
        fontWeight: 400,
        "&::placeholder": {
          fontFamily: "proxima-nova",
          fontSize: "17px !important",
          fontWeight: 400,
          color: "#B1B7C3 !importants",
        },
      },
    },
    searchContainerBox: {
      position: "absolute",
      right: 0,
      height: "40px",
    },
    searchBtnShow: {
      borderRadius: "3px",
      height: "45px",
      width: "122px",
      marginLeft: "-2px",
      cursor: "pointer",
      transition: "0.2s all ease-in-out",
      overflow: "hidden",
    },
    searchButton: {
      width: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      padding: 0,
      transition: "0.2s all ease-in-out",
      minHeight: "30px",
      height: "100%",
      borderRadius: "0px",
      border: "none",
    },
    categoryImgContainer: {
      width: "100%",
      gap: 15,
      [theme.breakpoints.down("lg")]: {
        marginBottom: "30px",
      },
      [theme.breakpoints.down("md")]: {
        marginBottom: "10px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "auto",
      },
    },
    categoryImg: {
      aspectRatio: "4/3",
      maxWidth: "385px",
      objectFit: "cover",
      cursor: "pointer",
      transition: "all .2s cubic-bezier(0.36, 1.26, 0.275, 0.97)",
      boxShadow: "0 9px 20px -8px rgba(32, 33, 36, .3)",
      "&:hover": {
        scale: "1.05",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100px",
      },
    },
    categoryDescription: {
      fontSize: "17px !important",
      fontWeight: "600",
      fontFamily: "proxima-nova",
      lineHeight: "24px",
      color: "#202124",
      cursor: "pointer",
      "&:hover": {
        textDecorationLine: "underline",
        textDecorationColor: "#0DA1EC",
        textUnderlineOffset: "5px",
        textDecorationThickness: "3px",
        transition: "0.2s all linear",
      },
    },
    paginationContainer: {
      display: "flex !important",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
    bodyPagination: {
      "& .MuiPaginationItem-root": {
        width: "40px",
        height: "40px",
        gap: 12,
        borderRadius: "50%",
        color: "#1D264A",
        outline: "none",
        "&:hover": {
          background: "#ddd",
        },
      },

      "& .MuiPaginationItem-root.Mui-selected": {
        background: "#1a91f0",
        color: "white",
        "&:hover": {
          background: "#1a91f0",
          color: "white",
        },
      },
      "& .MuiPaginationItem-icon": {
        color: "#BEC4D5",
      },
    },
    paginationMobileContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-around",
      alignItems: "center",
      textAlign: "center",
      marginTop: "10px",
      marginBottom: "10px",
    },
    mobiePaginationContainer: {
      width: "56px",
      height: "56px",
      background: "#EFF2F9",
      borderRadius: "50%",
      padding: 5,
      display: "none",
      justifyContent: "center",
      alignItems: "center",
      color: "#303848 !important",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
    },
    searchIcon: { color: "#B1B7C3", fontWeight: 400 },

    // Search Result section
    searchResultText: {
      fontSize: "22px !important",
      fontFamily: "proxima-nova",
      fontWeight: "600",
      color: "#202124;",
    },
    // Search Result section
    resultSection: {
      background: "#fff",
      borderTop: "1px solid #e4e8f0",
    },
    bottomSearchContainer: {
      background: "#f6f7fa",
      paddingBlock: "40px",
      borderTop: "1px solid #e4e8f0",
    },
    noResultContainer: {
      marginBottom: "40px",
    },
    resultText: {
      color: "black",
      fontSize: "22px !important",
      lineHeight: 1.4,
      fontWeight: 600,
      fontFamily: "proxima-nova",
    },
    searchInputContainer: {
      background: "#f6f7fa",
      padding: "30px 0px 70px 0",
      borderTop: "1px solid #e4e8f0",
    },
    inputSearchResultContainer: {
      background: "white",
      display: "flex",
      alignItems: "center",
      padding: "5px 10px",
      borderRadius: "5px",
      gap: "5px",
      width: "253px",
      height: "37.09px",
    },
    searchInputBtnContainer: {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      top: "40px",
      height: "40px",
    },
    searchText: {
      color: "#202124",
      fontSize: "20px !important",
      fontFamily: "proxima-nova",
    },
    clearIcon: {
      cursor: "pointer",
      color: "#5071c7",
    },
  }
})
