import React from 'react'
import CareerResources from '@talentinc/gatsby-theme-ecom/components/CareerResources/CareerResources'
import CareerSpecialist from '@talentinc/gatsby-theme-ecom/components/CareerSpecialist/CareerSpecialist'
import HomeFAQ from '@talentinc/gatsby-theme-ecom/components/HomeFAQ/HomeFAQ'
import HomeHero from '@talentinc/gatsby-theme-ecom/components/HomeHero/HomeHero'
import HomePress from '@talentinc/gatsby-theme-ecom/components/HomePress/HomePress'
import LearnMore from '@talentinc/gatsby-theme-ecom/components/LearnMore/LearnMore'
import NewFeatures from '@talentinc/gatsby-theme-ecom/components/NewFeatures/NewFeatures'
import FeaturedIn from '@talentinc/gatsby-theme-ecom/components/FeaturedIn/FeaturedIn'
import ReviewSection from '@talentinc/gatsby-theme-ecom/components/Reviews/ReviewSection'
import RoundBackgroundBlock from '@talentinc/gatsby-theme-ecom/components/RoundBackgroundBlock/RoundBackgroundBlock'
import TestimonialsV2 from '@talentinc/gatsby-theme-ecom/components/TestimonialsV2/TestimonialsV2'
import {
  RBBVariants,
  ReviewComponentVariants,
} from '@talentinc/gatsby-theme-ecom/types/landingPageV2'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import {
  PageContextType,
  TrustPilotRatingWidgetVariants,
} from '@talentinc/gatsby-theme-ecom/types/page'
import FooterCTA from '@talentinc/gatsby-theme-ecom/components/FooterCTA/FooterCTA'
import Section from '@talentinc/gatsby-theme-ecom/components/Section/Section'
import TopLayout from '@talentinc/gatsby-theme-ecom/layouts/TopLayout'

type Props = {
  pageData: any
  pageContext: PageContextType
}

const HomePage = ({ pageData, pageContext }: Props) => {
  return (
    <TopLayout pageData={pageData} pageContext={pageContext}>
      <Section>
        <HomeHero />
      </Section>
      <Section slideOnMobile noVerticalPadding>
        <FeaturedIn />
      </Section>
      <Section>
        <NewFeatures />
      </Section>
      <Section innerPadded>
        <LearnMore />
      </Section>
      <Section>
        {/* @TODO: Refactor Trust pilot block to not rely on Contentful */}
        <ReviewSection
          reviewSection={
            {
              customReviewComponent: ReviewComponentVariants.ProgressBarCarousel,
              body: {
                raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Reviewed by the community. Trusted by professionals.","nodeType":"text"}],"nodeType":"heading-2"},{"data":{"target":{"sys":{"id":"5VPTxAFigVzMq7fMgrg7UG","type":"Link","linkType":"Entry"}}},"content":[],"nodeType":"embedded-entry-block"},{"data":{},"content":[{"data":{},"marks":[],"value":"","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
                references: [
                  {
                    brand: 'Default Site Brand',
                    contentful_id: '5VPTxAFigVzMq7fMgrg7UG',
                    variant: TrustPilotRatingWidgetVariants.FiveStarsDescriptive,
                    __typename: 'ContentfulTrustPilotRatingWidget',
                  },
                ],
              },
            } as any
          }
        />
      </Section>
      <Section innerPadded>
        <TestimonialsV2 />
      </Section>
      <Section innerPadded>
        <CareerSpecialist />
      </Section>
      <Section>
        <RoundBackgroundBlock
          variant={RBBVariants.HomeResumeExamples}
          content={{ raw: "" }}
          codeClasses={null}
        />
      </Section>
      <Section innerPadded>
        <HomeFAQ />
      </Section>
      <Section innerPadded>
        <CareerResources />
      </Section>
      <Section innerPadded>
        <HomePress />
      </Section>
      <FooterCTA />
      <Footer useSimulatedPageLoad={false} />
    </TopLayout>
  )
}

export default HomePage
