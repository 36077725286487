import React from 'react'
import HeroSection from '@talentinc/gatsby-theme-ecom/components/Blog/Article/HeroSection'
import BodySection from '@talentinc/gatsby-theme-ecom/components/Blog/Article/BodySection'
import TopLayout from '@talentinc/gatsby-theme-ecom/layouts/TopLayout'
import { PageContextType, PageDataType } from '@talentinc/gatsby-theme-ecom/types/page'
import FooterCTA from '@talentinc/gatsby-theme-ecom/components/FooterCTA/FooterCTA'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import RelatedArticleSection from '@talentinc/gatsby-theme-ecom/components/Blog/Article/RelatedArticles'

export default function ArticlePage({
  pageContext,
  pageData,
}: {
  pageContext: PageContextType
  pageData: PageDataType
}) {
  return (
    <TopLayout pageContext={pageContext} pageData={pageData}>
      <HeroSection />
      <BodySection />
      <RelatedArticleSection />
      <FooterCTA />
      <Footer />
    </TopLayout>
  )
}
