import React, { useEffect, useState } from "react"
import { Box, Grid, Typography, InputBase, Stack, Button } from "@mui/material"
import { useStyles } from "./style"
import CategoryDropdown from "./CategoryDropdown"
import SearchIcon from "@mui/icons-material/Search"
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next"
import { AllCategory, CategoryBySlug } from "@talentinc/gatsby-theme-ecom/types/blog/category"

interface CategoryPageComponentProps {
  categoryBySlug?: CategoryBySlug | null
  allCategory: AllCategory
  searchVal?: string
  onSearchSubmit: (value: string) => void
}

export default function HeroSection({
  categoryBySlug,
  allCategory,
  searchVal,
  onSearchSubmit,
}: CategoryPageComponentProps) {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const [searchText, setSearchText] = React.useState<string>("")
  const [searchBtn, setSearchBtn] = useState<Boolean>(false)

  useEffect(() => {
    if (searchVal) setSearchText(searchVal)
  }, [searchVal])  

  return (
    <Box
      sx={{
        bgcolor: `${categoryBySlug ? categoryBySlug?.backgroundColor : "#E4E8F0"}`,
      }}
    >
      <Box className={classes.categorySectionContainer}>
        <Grid container>
          <Grid xs={12} item className={classes.dropDownGrid}>
            <Grid container>
              <Grid xs={12} sm={6} item>
                <CategoryDropdown allCategory={allCategory} />
              </Grid>
              <Grid className={classes.categorySearchGrid} sm={6} position="relative" item>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    onSearchSubmit(searchText)
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"end"}
                    alignItems={"center"}
                    className={classes.searchContainerBox}
                  >
                    <Box className={classes.inputContainer}>
                      <SearchIcon className={classes.searchIcon} />
                      <InputBase
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder={t("blog.category.hero.searchPlaceholder")}
                        onFocus={() => setSearchBtn(true)}
                        onBlur={() => setSearchBtn(false)}
                      />
                      <Box onMouseDown={(e)=> {e.stopPropagation(); setSearchText("")}}>
                      <ClearIcon className={classes.clearIcon} sx={{ display:`${searchText ? "block" : "none"}`}}/>
                      </Box>
                    </Box>
                    <Button type="submit" className={searchBtn ? classes.searchBtnShow : classes.searchButton}>
                      {t("blog.category.hero.searchButtonText")}
                    </Button>
                  </Stack>
                </form>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Typography className={classes.CategoryHeaderTitle}>
              {searchVal ? (
                <Typography className={classes.CategoryHeaderTitle}>
                  {" "}
                  {t("blog.category.hero.searchedForText")}: <br /> {searchVal}
                </Typography>
              ) : (
                <Typography className={classes.CategoryHeaderTitle}>
                  {t("blog.category.hero.heroTitle")}: {categoryBySlug?.name}
                </Typography>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
