import React from "react"
import { Box } from "@mui/material"
import BodyFeatureSection from "./BodyFeatureSection"
import { useBlogRootContext } from "@talentinc/gatsby-theme-ecom/contexts/BlogRootContext"

const BodySection = () => {
  const { categories, brandName } = useBlogRootContext()

  const filteredCategories = categories?.filter((category) => category.topArticles !== null && category.domain && brandName && category.domain?.includes(brandName))

  return (
    <Box>
      {filteredCategories?.map((category, index) => (
        <BodyFeatureSection
          categoryDetails={
            category.topArticles
              ? category.topArticles.filter(
                  (article) => article.domain && brandName && article.domain.includes(brandName)
                )
              : []
          }
          title={category.name || ""}
          viewAllUrl={category.slug || ""}
          bgColor={index % 2 === 0 ? "#F7F9FC" : ""}
        />
      ))}
    </Box>
  )
}

export default BodySection
